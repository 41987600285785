import React from "react"
import Layout from "../components/LayoutComponent"
import BackgroundImage from "../components/BackgroundImg"
import Image from "../components/CloudinaryImage"
import SEO from "../components/seo"

import { graphql, Link, useStaticQuery } from "gatsby"
import { Carousel, Col, Container, ListGroup, Row } from "react-bootstrap"
import netlifyStyle from "../../site/settings/style.json"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import CommercialContactForm from "../components/ContactModal"

// ! Specialty service 6 not in CMS

function Commercial() {
  const data = useStaticQuery(graphql`
    query {
      commercialPage: allFile(filter: { base: { eq: "commercial.md" } }) {
        edges {
          node {
            absolutePath
            relativeDirectory
            childMarkdownRemark {
              frontmatter {
                background
                main_heading
                main_prompt
                main_prompt_link
                heading_1
                intro_heading
                intro_description
                image_1
                heading_2
                image_2
                service_1
                service_2
                service_3
                service_4
                service_5
                heading_3
                specialty_description
                specialty_service_1
                specialty_service_2
                specialty_service_3
                specialty_service_4
                specialty_service_5
                specialty_service_6
                specialty_image_1
                specialty_image_2
                specialty_image_3
                specialty_image_4
                specialty_prompt
                specialty_prompt_link
              }
            }
          }
        }
      }
    }
  `)

  const commercialContent =
    data.commercialPage.edges[0].node.childMarkdownRemark.frontmatter
  const backgroundImageContainerStyle = {
    minHeight: "40vh",
    minWidth: "99.2vw",
    backgroundColor: "rgba(78, 78, 78, 0.651)",
  }

  const carouselItemStyle = {
    maxHeight: "350px",
  }

  const carouselImgStyle = {
    maxHeight: "350px",
    minHeight: "350px",
    height: "auto",
    width: "100%",
    //minWidth: "100%",
  }

  return (
    <div>
      <Layout>
        <SEO
          title="DNA Commercial Services"
          description="DNA offers specialized services for commercial customers."
          keywords="Commercial HVAC, Industrial HVAC, HVAC, New Construction, Service, Maintenance, Repairs"
        />
        <BackgroundImage markdownLink={commercialContent.background}>
          <Container
            className="d-flex align-items-center justify-content-center"
            style={backgroundImageContainerStyle}
          >
            <div
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease"
              className="d-flex flex-column align-items-center justify-content-center w-100 h-100"
            >
              <h1
                className="my-3 text-center mb-4"
                style={{ color: netlifyStyle.secondary_font_color }}
              >
                {commercialContent.main_heading}
              </h1>

              <CommercialContactForm
                custom={true}
                customText={"Get Started With Us"}
              />
            </div>
          </Container>
        </BackgroundImage>
        <Container className="d-flex flex-column align-items-center">
          <h2
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease"
            className="my-4 text-center"
          >
            {commercialContent.heading_1}
          </h2>
          <Row className="mb-4" style={{ minWidth: "100%" }}>
            <Col className="mb-3 h-50">
              <Image markdownLink={commercialContent.image_2} />
            </Col>

            <Col
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease"
              lg={7}
            >
              <Container>
                <ListGroup>
                  <ListGroup.Item>{commercialContent.service_1}</ListGroup.Item>
                  <ListGroup.Item>{commercialContent.service_2}</ListGroup.Item>
                  <ListGroup.Item>{commercialContent.service_3}</ListGroup.Item>
                  <ListGroup.Item>{commercialContent.service_4}</ListGroup.Item>
                  <ListGroup.Item>{commercialContent.service_5}</ListGroup.Item>
                </ListGroup>
              </Container>
            </Col>
          </Row>
          <h4
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease"
            className="mb-4 text-center"
          >
            {commercialContent.heading_3}
          </h4>
          <Row className={"mb-4"} style={{ minWidth: "100%" }}>
            <Col className="d-flex flex-column align-items-center justify-content-between mb-2">
              <p className="font-weight-bold">
                {commercialContent.specialty_description}
              </p>
              <ListGroup className="mb-2" style={{ width: "100%" }}>
                <ListGroup.Item>
                  {commercialContent.specialty_service_1}
                </ListGroup.Item>
                <ListGroup.Item>
                  {commercialContent.specialty_service_2}
                </ListGroup.Item>
                <ListGroup.Item>
                  {commercialContent.specialty_service_3}
                </ListGroup.Item>
                <ListGroup.Item>
                  {commercialContent.specialty_service_4}
                </ListGroup.Item>
                <ListGroup.Item>
                  {commercialContent.specialty_service_5}
                </ListGroup.Item>
                <ListGroup.Item>
                  {commercialContent.specialty_service_6}
                </ListGroup.Item>
              </ListGroup>

              <CommercialContactForm
                custom={true}
                customText={"Get Started With Us"}
              />
            </Col>
            <Col lg={8} className="mt-2">
              <div
                className=" align-self-center "
                style={{ maxHeight: "350px" }}
              >
                <Carousel>
                  <Carousel.Item style={carouselItemStyle}>
                    <Image
                      imgStyle={carouselImgStyle}
                      markdownLink={commercialContent.specialty_image_1}
                    />
                    <Carousel.Caption></Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item style={carouselItemStyle}>
                    <Image
                      imgStyle={carouselImgStyle}
                      class={"img-fluid"}
                      markdownLink={commercialContent.specialty_image_2}
                    />
                  </Carousel.Item>
                  <Carousel.Item style={carouselItemStyle}>
                    <Image
                      imgStyle={carouselImgStyle}
                      markdownLink={commercialContent.specialty_image_3}
                    />

                    <Carousel.Caption></Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item style={carouselItemStyle}>
                    <Image
                      imgStyle={carouselImgStyle}
                      markdownLink={commercialContent.specialty_image_4}
                    />

                    <Carousel.Caption />
                  </Carousel.Item>
                </Carousel>
              </div>
            </Col>
          </Row>
          <h4
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease"
            className="mb-4 text-center"
          >
            {commercialContent.intro_heading}
          </h4>
          <Row className="mb-4">
            <Col
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease"
              lg={7}
            >
              <p>{commercialContent.intro_description}</p>
              <Link
                className="text-uppercase font-weight-bold mb-4"
                to="/project-gallery"
                style={{ color: netlifyStyle.brand_color }}
              >
                View Our Projects <FontAwesomeIcon icon={faChevronRight} />{" "}
              </Link>
            </Col>
            <Col
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease"
              className="h-50"
            >
              <Image markdownLink={commercialContent.image_1} />
            </Col>
          </Row>
        </Container>
      </Layout>
    </div>
  )
}

export default Commercial
