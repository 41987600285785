import React from "react"

function CloudinaryImage(props) {
  /* const handleImg = cmsLink => {
    return first char of substring returned from search

    const publicIdIndex = cmsLink.search("/dna-images")

    const cloudinaryPublicId = cmsLink.slice(publicIdIndex)

    console.log(publicIdIndex)
    console.log(cloudinaryPublicId)
    return cloudinaryPublicId
  } */

  return <img style={props.imgStyle} src={`${props.markdownLink}`} />
}

export default CloudinaryImage

/* 

<Imageß
      style={props.imgStyle}
      className={props.class}
      publicId={handleImg(props.markdownLink)}
    >
         <Transformation crop="scale" /> 
      </Image> 

*/
