import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

const StyledBackgroundSection = styled(BackgroundImg)`
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`
function BackgroundImg(props) {
  /*  const handleImg = contentLink => {
    let path = ""
    let imgData

    data.images.edges.map(el => {
      path = "/img/" + el.node.base
      if (path === contentLink) {
        imgData = el.node.childImageSharp.fluid
        return imgData
      }
    })
    return imgData
  } */

  return (
    <React.Fragment>
      <div
        style={{
          background: `url("${props.markdownLink}") no-repeat center center/cover`,
        }}
      >
        {props.children}
      </div>
    </React.Fragment>
  )
}

export default StyledBackgroundSection
