import React from "react"
import { useState } from "react"
import { Modal, Button } from "react-bootstrap"
import Contact from "./ContactForm"
import netlifyStyles from "../../site/settings/style.json"
import netlifyPromo from "../../site/settings/promo.json"

const btnStyles = {
  backgroundColor: netlifyStyles.brand_color,
  borderColor: netlifyStyles.brand_color,
}

function ContactModal(props) {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <React.Fragment>
      <Button style={btnStyles} onClick={handleShow}>
        {!props.custom ? netlifyPromo.mailchimp_form_prompt : props.customText}
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton style={{ backgroundColor: "#F8F9FA" }}>
          <Modal.Title className="text-center">
            {!props.custom
              ? netlifyPromo.mailchimp_form_prompt
              : props.customText}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#F8F9FA" }}>
          <Contact />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

export default ContactModal
